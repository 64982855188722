/* Sidebar */
.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $white;
  font-family: $TYPE-2;
  min-height: 100%;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function;

  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;

    .nav-item {
      .collapse {
        z-index: 999;
      }

      .nav-link {
        align-items: center;
        display: flex;
        padding: 0.63rem 1.50rem 0.75rem 2.06rem;
        height: $nav-link-height;
        white-space: nowrap;
        color: color(gray-light);

        i {
          &.menu-icon {
            margin-right: 0.94rem;

            &:after,
            &:before {
              font-size: 1.25rem;
            }
          }

          &.menu-arrow {
            &:after,
            &:before {
              font-size: 0.9rem;
            }
            margin-left: auto;
          }
        }

        .menu-title {
          font-family: $TYPE-2;
          display: inline-block;
          font-size: $default-font-size;
        }

        &:hover {
          color: darken(color(gray-light), 15%);
        }
      }

      &.nav-category {
        color: theme-color(dark);
        font-family: $TYPE-2;
        font-size: $default-font-size;
        line-height: 16px;

        .nav-link {
          padding: 1.2rem 2rem 0.2rem;

          &:hover {
            color: theme-color(dark);
          }
        }
      }

      &.active {
        position: relative;
        color: #526168;
        background: #f7f7f7;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 4px;
          height: $nav-link-height;
          background: theme-color(primary);
        }

        .sub-menu {
          .nav-item {
            .nav-link {
              &.active {
                color: theme-color(primary);
              }
            }
          }
        }
      }
    }

    &.sub-menu {
      padding-left: 2.5em;

      .nav-item {
        .nav-link {
          padding: 0.5em 2.5em;
          font-size: 13px;
          position: relative;
          height: $sidebar-submenu-link-height;
          &:before{
            position: absolute;
            top: 9px;
            left: 0;
            font-size: 12px;
            content: "\F6f7";
            display: inline-block;
            font-family: Material Design Icons;
            text-rendering: auto;
          }
        }

        &.active {
          .nav-link {
            border-left: 0;
            color: theme-color(primary);
            background: transparent;
          }
        }
      }
    }
  }
  //sidebar color variation
}

.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg;

    .nav {
      .nav-item {
        .nav-link {
          .menu-title,
          i {
            color: $sidebar-dark-menu-color;
          }
        }

        &.active {
          background: $sidebar-dark-menu-active-bg;
        }
        &.hover-open{
          .collapse{
            background: $sidebar-dark-bg !important;
          }
        }
      }

      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;
          }
        }
      }
    }
  }
}
/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}