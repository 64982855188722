/* Landing */

.landing-page {
	.navbar {
		padding: 2rem 0;
		box-shadow: none;
		-webkit-box-shadow: none;
		.navbar-brand {
			img {
				width: 145px;
			}
		}
		.navbar-collapse {
			@media (max-width: 991px) {
				display: block;
			}
			&.collapse {
				display: none;
			}
			&.show {
				display: block;
			}
			.navbar-nav {
				.nav-item {
					.nav-link {
						color: $white;
						padding-left: 1rem;
						padding-right: 1rem;
						font-size: .9375rem;
						font-family: $TYPE-2;
						font-weight: 700;
						text-align: left;
						@media (min-width: 992px) {
							margin-left: 1rem;
							margin-right: 1rem;
							&:first-child {
								margin-left: 0;
							}
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
	.top-banner {
		background: url("../images/samples/Banner_bg.jpg");
		background-size: cover;
		.demo-image {
			img {
				max-width: 500px;
				@media (min-width: 992px) {
					position: absolute;
					left: -80px;
				}
				@media (max-width: 991px) {
					margin-top: 1rem;
					max-width: 100%;
				}
			}
		}
		.top-banner-content {
			padding-top: 80px;
			padding-bottom: 120px;
		}
	}
	.middle-section {
		padding-top: 120px;
		padding-bottom: 80px;
		text-align: center;
		color: $black;
		.card {
			background: $white;
			border-radius: 10px;
			box-shadow: 5px 5px 25px 0 rgba(46,61,73,.2);
			border: 1px solid $white-smoke;
			@include transition(box-shadow .5s ease);
			&:hover {
				box-shadow: 2px 4px 8px 0 rgba(46,61,73,.2);
			}
			&.card-icon-top {
				position: relative;
				.card-body {
					padding: 3rem;
				}
				.card-icon {
					position: absolute;
					width: 65px;
					height: 65px;
					top: -30px;
					left: calc(50% - 30px);
				}
			}
			&.card-item-preview {
				position: relative;
				.badge {
					position: absolute;
					top: -25px;
					right: -35px;
					width: 65px;
					height: 65px;
					padding: 1rem;
					border-radius: 50%;
					line-height: 32px;
					font-size: 1rem;
				}
				.card-img-top {
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
				}
			}
		}
	}
	.bottom-section {
		color: $black;
		text-align: center;
		padding-top: 80px;
	}
	.feature-list {
		margin-top: 100px;
		margin-bottom: 100px;
		.feature-list-row {
			&:first-child {
				@media (min-width: 992px) {
					border-bottom: 1px solid $white-smoke;
				}
			}
			.feature-list-item {
				padding-top: 1rem;
				padding-bottom: 2rem;
				i {
					display: block;
					font-size: 1.875rem;
					margin-bottom: 2rem;
				}
				h4 {
					@extend .font-weight-normal;
				}
				p {
					@extend .font-weight-light;
				}
				@media (min-width: 992px) {
					border-right: 1px solid $white-smoke;
					&:last-child {
						border-right: 0;
					}
				}
				.feature-description {
					font-family: $TYPE-1;
				}
			}
		}
	}
	.footer {
		background: url("../images/samples/footer_bg.jpg");
		margin: 0;
		width: 100%;
		padding-top: 80px;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		.footer-top {
			border-bottom: 1px solid rgba($white, .2);
			.brand-logo {
				width: 145px;
			}
			.footer-social-icons {
				margin-top: 3rem;
				i {
					width: 43px;
					height: 43px;
					font-size: 1rem;
					border-radius: 100%;
					text-align: center;
					line-height: 43px;
					margin-right: .5rem;
				}
			}
			.contact-details {
				i {
					font-size: 1.25rem;
					font-weight: 500;
				}
			}
			.nav {
				.nav-item {
					.nav-link {
						color: $white;
						border-right: 1px solid $border-color;
						padding-top: 0;
						padding-bottom: 0;
						font-size: .9375rem;
						font-family: $TYPE-1;
						line-height: 1;
					}
					&:last-child {
						.nav-link {
							border-right: 0;
						}
					}
					&:first-child {
						.nav-link {
							padding-left: 0;
						}
					}
				}
			}
			.form-control {
				background: lighten($black, 9%);
				border: 2px solid darken($white, 50%);
				border-radius: 1.25rem;
				padding-top: .75rem;
				padding-bottom: .75rem;
			}
		}
		.footer-bottom {
			color: $white;
			font-family: $TYPE-1;
			font-weight: 700;
		}
	}
}