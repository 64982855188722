/* Dropdowns */


.dropdown-menu {
  font-size: $default-font-size;
  -webkit-box-shadow: -3px 6px 27px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -3px 6px 27px 3px rgba(0, 0, 0, 0.1);
  box-shadow: -3px 6px 27px 3px rgba(0, 0, 0, 0.1);
  @unclude border-radius(5px);
  &[x-placement="bottom-start"]{
    transform: translate3d(0px, 51px, 0px) !important;
  }
  &[x-placement="top-start"]{
    transform: translate3d(0px, -199px, 0px) !important;
  }

  .dropdown-item {
    padding: 10px 15px;
    font-size: $default-font-size;
    i{
      margin-right: 10px;
      color: color(gray);
    }
    &:active {
      background: initial;
    }
  }
}