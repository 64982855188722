.new-accounts {
  overflow: hidden;
  position: relative;
  ul.chats {
    height: 100%;
    padding: 0px 0px 10px 0px;
    overflow-x: hidden;
    max-height: 460px;

    li.chat-persons {
      padding: 10px 0px 10px 0px;
      display: block;
      border-bottom: $border-property;

      a {
        @extend .d-flex;
        @extend .align-items-center;
        text-decoration: none;
        span.pro-pic {
          display: inline-block;
          padding: 0;
          width: 20%;
          max-width: 40px;

          img {
            max-width: 100%;
            width: 100%;
            @include border-radius(100%);
          }
        }

        div.user {
          width: 60%;
          @extend .d-flex;
          @extend .flex-column;
          padding: 5px 10px 0 15px;

          p.u-name {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: rgba(32, 71, 92, 0.91);
            line-height: 18px;
            @extend %ellipsor;
          }

          p.u-designation {
            margin: 0;
            font-size: 11px;
            color: $dodger-blue;
            @extend %ellipsor;
          }
        }
        p.joined-date{
          text-align: right;
          margin-left: auto;
          margin-bottom: 0;
          color: darken(color(teal), 20%);
        }
      }
    }
  }
}
