// Solid tab variations
@mixin tab-solid-variant($color) {
  .nav-link {
    &.active {
      background: $color;
    }
  }
}
// Minimal tab variations
@mixin tab-filled-variant($color) {
  background: rgba($color,0.1);
  .nav-tabs {
    .nav-item {
      .nav-link {
        &.active {
          background: rgba($color,0.3);
          color: $color;
        }
      }
    }
  }

  .tab-content {
    border: $color;
    background: rgba($color,0.3);
    color: $black;
  }
}