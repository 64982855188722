/* Tables */
.table {
  margin-bottom: 0;
  @extend .table-bordered;
  @include border-radius(4px);
  overflow: hidden;
  border: none;

  thead {
    th {
      border-top: none;
      color: color(gray);
      font-family: $TYPE-1;
      font-weight: 700;
      font-size: 15px;
      line-height: 1;
      background: $white;
    }
  }

  td,
  th {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;

    img {
      width: 34px;
      height: 34px;
      border-radius: 100%;
    }

    .badge {
      margin-bottom: 0;
    }
  }

  tr {
    td {}

    &.bg-dark {
      td,
      th {
        color: $white;
      }
    }
  }

  td {
    color: color(gray);
  }

  td,
  th {
    border-left: 0;
    border-right: 0;
    vertical-align: middle;
  }

  &.table-borderless {
    border: none;

    td,
    th,
    tr {
      border: none;
    }
  }
}