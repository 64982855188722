/* Miscellanoeous */

body,
html {
  overflow-x: hidden;
  padding-right: 0 !important; // resets padding right added by Bootstrap modal
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto !important;
}
.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 1.5rem 1.7rem;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

.profile-img{
  position: relative;
  height: 34px;
  width: 34px;
  img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    @include border-radius(100%);
  }
  .count-indicator{
    position: absolute;
    bottom: 2px;
    right: 0;
    display: block;
    height: 8px;
    width: 8px;
    z-index: 999;
    @include border-radius(100%);
    &.online-status{
      &.online{
        background: theme-color(success);
      }
    }
  }
  &.profile-lg{
    width: 88px;
    height: 88px;
  }
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}