/* Typography */
body {
  font-family: $TYPE-1;
  font-size: $default-font-size;
  font-weight: initial;
  color: color(gray);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $TYPE-1;
  font-weight: 700;
  color: color(gray);
}

p {
  font-family: $TYPE-1;
  font-size: $default-font-size;
  color: color(gray);
}

.h1,
h1 {
  font-size: 35px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 25px;
}

.h4,
h4 {
  font-size: 20px;
}

.h5,
h5 {
  font-size: 15px;
}

.h6,
h6 {
  font-size: $default-font-size;
}

p {
  font-size: 0.88rem;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5 {
  font-weight: 700;
}

.display-1 {
  font-size: 40px;
  @media (max-width: 991px) {
    font-size: 2.125rem;
  }
}

.display-2 {
  font-size: 35px;
  @media (max-width: 991px) {
    font-size: 1.8rem;
  }
}

.display-3 {
  font-size: 25px;
  @media (max-width: 991px) {
    font-size: 1.45rem;
  }
}

.display-4 {
  font-size: 20px;
  @media (max-width: 991px) {
    font-size: 1.1rem;
  }
}

.display-5 {
  font-size: 18px;
  @media (max-width: 991px) {
    font-size: 1rem;
  }
}

.blockquote {
  padding: 1.25rem;
  border: 1px solid $border-color;
}

address {
  p {
    margin-bottom: 0;
  }
}

.page-title {
  color: $black;
  margin: 0.38rem 0 0.75rem;
}

.card-title {
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: color(gray-light);
}

.card-subtitle {
  @extend .text-gray;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.card-description {
  margin-bottom: 0.9375rem;

  .rtl & {
    text-align: right;
  }
}

.font-weight-normal {
  font-weight: 400 !important;
}

.bold-text {
  font-weight: 700 !important;
}

.text-small {
  font-size: $default-font-size;
}

.icon-lg {
  font-size: 2.5rem;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem;
}

//blockqoute color variations
@each $color, $value in $theme-colors {
  .blockquote-#{$color} {
    @include blockquote($value);
  }
}