/* Navbar */
.navbar {
  background: $white;
  border-bottom: $border-property;
  z-index: 999;

  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    height: $navbar-height;
    width: $sidebar-width-lg;
    border-right: $border-property;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);

    .navbar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
      width: 75%;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        max-width: 100%;
      }
    }

    .brand-logo-mini {
      display: none;
      max-width: 35px;
    }
  }

  .navbar-menu-wrapper {
    height: $navbar-height;
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - #{$sidebar-width-lg});
    @media (max-width: 991px) {
      width: auto;
    }

    .navbar-toggler {
      border: 0;

      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
      }
    }

    .search {
      .form-control {
        border-left: none;
        color: color(gray-light);
        font-family: $TYPE-2;
        font-size: $default-font-size;
        background: transparent;
        border: none;
        @include input-placeholder {
          color: inherit;
          font-size: inherit;
          font-family: $TYPE-2;
        }
      }

      .input-group-addon {
        border: none;
        font-size: 25px;
        color: #dde3e7;
      }
    }

    .navbar-nav {
      flex-direction: row;
      align-items: center;

      .nav-item {
        .nav-link {
          font-size: 0.875rem;
          margin-left: 5px;
          margin-right: 15px;
          color: color(gray-light);
          font-family: $TYPE-2;
          i {
            font-size: 1.45rem;
            vertical-align: middle;
          }
        }
        &.color-variations{
          i{
            font-size: 1.6rem;
          }
        }

        &.nav-profile {
          .nav-link {
            display: flex;
          }

          img {
            width: 22px;
            height: 22px;
            border-radius: 100%;
            margin-right: 5px;
          }
        }

        &.notification {
          padding-left: 1.25rem;
          border-left: $border-property;
          height: $navbar-height;

          .count-indicator {
            position: relative;

            .count {
              position: absolute;
              left: 46%;
              width: 17px;
              height: 17px;
              top: 10px;
              color: $white;
              @include border-radius(50px);
              text-align: center;
              line-height: 1;
              padding: 3px 7px 1px 6px;
              font-size: 0.75rem;
            }
          }
        }

        &.dropdown {
          .dropdown-toggle {
            color: color(gray-light);
            font-family: $TYPE-2;
            font-weight: 400;

            i {
              &:before {
                width: 34px;
                height: 34px;
                background: color(gray-light);
                border-radius: 100%;
                line-height: 34px;
                text-align: center;
              }
            }
          }

          .navbar-dropdown {
            font-size: 0.9rem;
            margin-top: -10px;
            position: absolute;
            top: $navbar-height;
            right: 0;
            left: auto;
            @media (max-width: 390px) {
              right: -50px;
            }

            &:after,
            &:before {
              content: '';
              display: block;
              position: absolute;
              right: 0;
              width: 0;
              height: 0;
              border-style: solid;
            }

            &:after {
              top: -19px;
              border-color: transparent transparent $white transparent;
              border-width: 10px;
              left: 16px;
              right: auto;
            }

            &:before {
              top: -22px;
              border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
              border-width: 11px;
              left: 15px;
              right: auto;
            }
            -webkit-box-shadow: -3px 6px 27px 3px rgba(0, 0, 0, 0.10);
            -moz-box-shadow: -3px 6px 27px 3px rgba(0, 0, 0, 0.10);
            box-shadow: -3px 6px 27px 3px rgba(0, 0, 0, 0.10);
            border: 1px solid rgba(182, 182, 182, 0.1);
            padding: 0;
            @include border-radius(8px);
            overflow: hidden;

            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              border-bottom: 1px solid lighten(color(gray-light),30%);
              margin-bottom: 0;
              padding: 11px 13px;

              i {
                font-size: 17px;
              }

              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .dropdown-divider {
              margin: 0;
            }
            @media (min-width: 992px) {
              &:after {
                right: 16px;
                left: auto;
              }

              &:before {
                right: 15px;
                left: auto;
              }
            }

            &.preview-list {
              padding: 0;
              @extend .dropdownAnimation;
              z-index: 1;

              .preview-item {
                @include display-flex;
                @include flex-direction(row);
                @include align-items(flex-start);
                padding: 0.75rem 36px 10px 22px;
                font-size: 0.875rem;

                &:first-child {
                  padding-top: 0;
                }

                &:last-child {
                  padding-bottom: 0;
                  border-bottom: 0;
                }

                .preview-thumbnail {
                  color: color(white);
                  position: relative;

                  .preview-icon,
                  img {
                    width: 36px;
                    height: 36px;
                    border-radius: 100%;
                  }

                  .preview-icon {
                    padding: 6px;
                    text-align: center;
                    @include display-flex;
                    @include justify-content(center);
                    @include align-items(center);

                    i {
                      font-size: 1.125rem;
                      margin-right: 0;
                      color: $white;
                    }
                  }

                  .badge {
                    border: 2px solid color(white);
                    border-radius: 100%;
                    bottom: 5px;
                    display: block;
                    height: 14px;
                    left: -5px;
                    padding: 0;
                    position: absolute;
                    width: 14px;

                    &.badge-online {
                      @extend .badge-success;
                    }

                    &.badge-offline {
                      @extend .badge-info;
                    }

                    &.badge-busy {
                      @extend .badge-warning;
                    }
                  }
                }

                .preview-item-content {
                  line-height: 1;
                  padding-left: 15px;

                  &:first-child {
                    padding-left: 0;
                  }

                  p {
                    margin-bottom: 10px;

                    .content-category {
                      font-family: 'source-sans-pro-semibold', sans-serif;
                      padding-right: 15px;
                      border-right: 1px solid $border-color;
                      @extend .text-muted;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.nav-header-item-wrapper{
        margin-left: auto;
        .rtl & {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }
  //navbar color variations
  &.navbar-danger,
  &.navbar-info,
  &.navbar-pink,
  &.navbar-primary,
  &.navbar-success,
  &.navbar-warning,
  &.navbar-dark {
    .search {
      .input-group-addon {
        color: $white;
      }

      .form-control {
        color: $white;
        @include input-placeholder {
          color: $white;
        }
      }
    }

    .navbar-menu-wrapper {
      .navbar-toggler {
        color: $white;
      }

      .navbar-nav {
        .nav-item {
          color: $white;

          .nav-link {
            color: $white;

            &.count-indicator {
              .count {
                color: $white;
              }
            }
          }

          .dropdown-toggle {
            color: $white;
          }
        }
      }
    }
  }

  &.navbar-dark {
    background: theme-color(dark);

    .navbar-brand-wrapper {
      border-color: darken(theme-color(dark),5%);
    }

    .navbar-menu-wrapper {
      .navbar-nav {
        .nav-item {
          border-color: lighten(theme-color(dark),5%);

          .nav-link {
            &.count-indicator {
              .count {
                background: theme-color(danger);
              }
            }
          }
        }
      }
    }
  }

  &.navbar-primary {
    background: theme-color(primary);

    .navbar-brand-wrapper {
      border-color: darken(theme-color(primary),5%);
    }

    .navbar-menu-wrapper {
      .navbar-nav {
        .nav-item {
          border-color: darken(theme-color(primary),5%);

          .nav-link {
            &.count-indicator {
              .count {
                background: theme-color(danger);
              }
            }
          }
        }
      }
    }
  }

  &.navbar-success {
    background: theme-color(success);

    .navbar-brand-wrapper {
      border-color: darken(theme-color(success),5%);
    }

    .navbar-menu-wrapper {
      .navbar-nav {
        .nav-item {
          border-color: darken(theme-color(success),5%);

          .nav-link {
            &.count-indicator {
              .count {
                background: darken(theme-color(success),5%);
              }
            }
          }
        }
      }
    }
  }

  &.navbar-danger {
    background: theme-color(danger);

    .navbar-brand-wrapper {
      border-color: darken(theme-color(danger),5%);
    }

    .navbar-menu-wrapper {
      .navbar-nav {
        .nav-item {
          border-color: darken(theme-color(danger),5%);

          .nav-link {
            &.count-indicator {
              .count {
                background: darken(theme-color(danger),5%);
              }
            }
          }
        }
      }
    }
  }

  &.navbar-warning {
    background: theme-color(warning);

    .navbar-brand-wrapper {
      border-color: darken(theme-color(warning),5%);
    }

    .navbar-menu-wrapper {
      .navbar-nav {
        .nav-item {
          border-color: darken(theme-color(warning),5%);

          .nav-link {
            &.count-indicator {
              .count {
                background: darken(theme-color(warning),5%);
              }
            }
          }
        }
      }
    }
  }

  &.navbar-pink {
    background: color(pink);

    .navbar-brand-wrapper {
      border-color: darken(color(pink),5%);
    }

    .navbar-menu-wrapper {
      .navbar-nav {
        .nav-item {
          border-color: darken(color(pink),5%);

          .nav-link {
            &.count-indicator {
              .count {
                background: darken(color(pink),10%);
              }
            }
          }
        }
      }
    }
  }

  &.navbar-info {
    background: theme-color(info);

    .navbar-brand-wrapper {
      border-color: darken(theme-color(info),5%);
    }

    .navbar-menu-wrapper {
      .navbar-nav {
        .nav-item {
          border-color: darken(theme-color(info),5%);

          .nav-link {
            &.count-indicator {
              .count {
                background: darken(theme-color(info),5%);
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;

    .navbar-brand-wrapper {
      width: 75px;

      .brand-logo {
        display: none;
      }

      .brand-logo-mini {
        display: inline-block;
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}