/* Layouts */


.navbar {
  &.fixed-top {
    + .page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}

// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-mini;
      }

      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-mini});
      }
    }

    .sidebar {
      width: $sidebar-width-mini;

      .nav {
        .nav-item {
          height: auto;

          &:before {
            height: 82px;
            @at-root #{selector-append(".sidebar-icon-only", &)}{
              height: $nav-link-height;
            }
          }

          .nav-link {
            display: block;
            text-align: center;
            padding: 0.8em 2.5em;
            height: auto;

            .menu-title {
              display: block;
            }

            i {
              &:last-child {
                display: none;
              }
            }

            &[data-toggle="collapse"] {
              .menu-title {
                position: relative;
                &:after {
                  content: "\F140";
                  font-family: "Material Design Icons";
                  position: absolute;
                  top: 5px;
                  right: -25px;
                  font-size: 0.7rem;
                }
              }
            }
          }

          &.nav-category {
            display: none;
          }
        }

        &.sub-menu {
          padding: 0 0.5rem;

          .nav-item {
            .nav-link {
              padding: 0.5rem 0;
              text-align: center;
            }
          }
        }
      }
    }

    .main-panel {
      width: calc(100% - #{$sidebar-width-mini});
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  &:not(.sidebar-expanded) {
    @media (min-width: 992px) {
      .navbar {
        .navbar-brand-wrapper {
          width: $sidebar-width-icon;

          .brand-logo {
            display: none;
          }

          .brand-logo-mini {
            display: inline-block;
          }
        }

        .navbar-menu-wrapper {
          width: calc(100% - #{$sidebar-width-icon});
        }
      }

      .sidebar {
        width: $sidebar-width-icon;

        .nav {
          overflow: visible;

          .nav-item {
            position: relative;

            .nav-link {
              display: block;
              padding: 0.6rem;
              text-align: center;

              .menu-title {
                display: none;
              }

              i {
                &.menu-icon {
                  margin-right: 0;
                }

                &.menu-arrow {
                  display: none;
                }
              }
            }

            &.nav-profile {
              display: none;
            }

            &.nav-category {
              display: none;
            }

            .collapse {
              display: none;
            }

            &.hover-open {
              .nav-link {
                .menu-title {
                  background: theme-color(primary);
                  color: color(white);
                  padding: 0.5rem 2rem;
                  display: inline-block;
                  left: $sidebar-width-icon;
                  position: absolute;
                  text-align: left;
                  top: 0;
                  width: $sidebar-width-lg;
                  z-index: 1;
                  height: $nav-link-height;
                  line-height: 2;
                  @at-root #{selector-append(".rtl", &)} {
                    left: auto;
                    right: $sidebar-width-icon;
                  }

                  &:after {
                    display: none;
                  }
                }
              }

              .collapse,
              .collapsing {
                display: block;
                background: color(white);
                padding: 0.5rem 0;
                position: absolute;
                top: $nav-link-height;
                left: calc(15px + #{$sidebar-width-icon});
                width: calc(#{$sidebar-width-lg - 15px});
                @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: calc(15px + #{$sidebar-width-icon});
                }
                -webkit-box-shadow: 0 1px 15px 1px rgba(113, 106, 202, 0.11);
                -moz-box-shadow: 0 1px 15px 1px rgba(113, 106, 202, 0.11);
                box-shadow: 0 1px 15px 1px rgba(113, 106, 202, 0.11);
              }
            }
          }

          &.sub-menu {
            .nav-item {
              .nav-link {
                text-align: left;
                padding: 0.5rem 1.2rem;
              }
            }
          }
        }
      }

      .main-panel {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .sidebar {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      width: 0;
    }

    .main-panel {
      width: 100%;
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    .page-body-wrapper {
      position: relative;
      .sidebar {
        transition: none;
      }
    }
    &:not(.sidebar-hidden) {
      .sidebar {
        position: absolute;
        height: 100%;
        -webkit-box-shadow: 0px 0px 3px 1px rgba(167, 163, 163, 0.18);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow:    0px 0px 3px 1px rgba(167, 163, 163, 0.18);  /* Firefox 3.5 - 3.6 */
        box-shadow:         0px 0px 3px 1px rgba(167, 163, 163, 0.18);  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      }
    }

    .main-panel {
      width: 100%;
      transition: none;
    }
  }
}

//Horizontal Menu
.horizontal-menu {
  @media (min-width: 992px) {
    .page-body-wrapper {
      @include flex-direction(column);
    }
    .navbar {
      .navbar-brand-wrapper {
        border-bottom: 1px solid $border-color;
      }
    }
    .navbar-toggler {
      &[data-toggle="minimize"],
      &[data-toggle="toggleSidebarDisplay"] {
        display: none;
      }
    }

    .main-panel {
      width: 100%;
    }

    .sidebar{
      width: 100%;
      min-height: auto;
      height: $horizontal-menu-height;
      border-top: 1px solid rgba($sidebar-dark-menu-color, .3);
      border-bottom: 1px solid $border-color;
      .nav {
        flex-direction: row;
        margin-bottom: 0;
        overflow: visible;
        padding: $horizontal-menu-padding;
        .nav-item {
          position: relative;
          padding: $horizontal-menu-item-padding;
          .nav-link {
            height: auto;
            .menu-icon {
              font-size: $horizontal-menu-icon-font-size;
              line-height: 1;
            }
            .menu-title {
              font-size: $horizontal-menu-font-size;
              line-height: 1;
            }
          }
          &.mega-menu {
            position: static;
          }
          &.hover-open {
            .collapse,
            .collapsing {
              display: block;
              background: color(white);
              position: absolute;
              top: $horizontal-menu-height;
              left: 0;
              min-width: 100%;
              padding: 0 1rem;
              @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: 0;
              }
              -webkit-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
              -moz-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
              box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
            }
            &.mega-menu {
              .collapse,
              .collapsing {
                width: auto;
                top: calc(#{$horizontal-menu-height} + #{$navbar-height});
                .mega-menu-section {
                  width: auto;
                  margin-left: 15px;
                  margin-right: 15px;
                  float: left;
                  .mega-menu-title {
                    .nav-link {
                      color: $black;
                      font-size: 1rem;
                      line-height: 1;
                    }
                  }
                  .mega-menu-title,
                  .nav-item {
                    .nav-link {
                      padding-left: 0;
                      padding-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
        &.sub-menu {
          padding: 0;
          .nav-item {
            padding: 0;
            .nav-link {
              color: $horizontal-menu-submenu-color;
              padding: $horizontal-menu-submenu-item-padding;
              border-bottom: 1px solid $border-color;
              &:before {
                display: none;
              }
            }
            &:last-child {
              .nav-link {
                border-bottom: 0;
              }
            }
          }
        }
      }
      >.nav {
        >.nav-item {
          >.nav-link {
            border-right: 1px solid rgba($sidebar-dark-menu-color, .3);
            padding: .55rem 1.5rem;
          }
          &:last-child {
            .nav-link {
              border-right: none;
            }
          }
        }
      }
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  @media(min-width: 992px) {
    .sidebar {
      position: fixed;
      max-height: auto;
      .nav {
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto;
        position: relative;
        &.sub-menu {
          max-height: none;
        }
      }
    }
    .main-panel {
      margin-left: $sidebar-width-lg;
    }
    &.sidebar-icon-only {
      .main-panel {
        margin-left: $sidebar-width-icon;
      }
    }
  }
}

.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: darken(color(gray-lightest), 5%);
      padding: 0 (100% - $boxed-container-width) / 2;
    }

    .navbar {
      &.fixed-top {
        margin: auto;
        width: $boxed-container-width;
      }
    }

    &.horizontal-menu {
      .sidebar {
        margin: auto;
        width: $boxed-container-width;
      }

      &.horizontal-menu-top {
        .sidebar {
          width: calc(#{$boxed-container-width} - #{$sidebar-width-lg});
        }
      }
    }
  }
}

.rtl {
  direction: rtl;

  .sidebar {
    .nav {
      padding-right: 0;

      .nav-item {
        .nav-link {
          i {
            &.menu-icon {
              margin-right: 0;
              margin-left: 0.5em;
            }

            &.flaticon-arrows {
              @include rotate (180);
            }

            &.menu-arrow {
              margin-left: 0;
              margin-right: 0.5em;
            }
          }
        }
      }

      &.sub-menu {
        padding-left: 0;
        padding-right: 2.5em;
      }
    }
  }

  @at-root body#{&}, div, ul {
    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}