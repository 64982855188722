/* Forms */


.input-group-append,
.input-group-prepend {
  background: $input-bg;
  color: $input-placeholder-color;
  width: auto;
  border: none;

  .input-group-text {
    background: transparent;
    border-color: $border-color;
    border-width: 2px;
  }
}

.form-control {
  border: 2px solid $border-color;
  font-family: $TYPE-1;
  font-weight: 400;
  font-size: $input-font-size;
  padding: $btn-padding-y .75rem;
  line-height: 14px;
  background: $input-bg;

  &.form-control-lg {
    padding: $input-btn-padding-y-lg .75rem;
  }

  &.form-control-sm {
    padding: $input-btn-padding-y-sm .75rem;
  }
}

textarea {
  background: $input-bg;
}

select {
  &.form-control {
    padding: 0.4375rem 0.75rem;
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
  }

  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }

  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }

  .file-upload-info {
    background: $input-bg;
  }
}

@each $color, $value in $theme-colors {
  .input-group-append,
  .input-group-prepend {
    &.bg-#{$color} {
      .input-group-text {
        border-color: $value;
      }
    }
  }
}