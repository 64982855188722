/* Tabs */

// Basic Styles
.nav-tabs,
.nav-pills{
	.nav-item{
		.nav-link{
			font-family: $TYPE-1;
			font-weight: 700;
			line-height: 1;
			padding: 13px 28px;
			font-size: $default-font-size;
			color: $black;
			@include display-flex;
			@include border-radius(2px);
			i{
				margin-right: 10px;
			}
		}
	}
}
.tab-content{
	padding: 37px 18px 29px 24px;
	font-family: $TYPE-1;
	font-size: $default-font-size;
	line-height: 1.71;
	border: 2px solid $border-color;
	@include border-radius(5px);

}

// Basic Tab Styles
.tab-basic{
	border-bottom: none;
	background: $content-bg;
	.nav-item{
		.nav-link{
			color: color(gray-light);
			&.active{
				border: none;
				color: color(gray-dark);
				background: darken($content-bg,15%);
			}
		}
	}
}
.tab-content-basic{
	border: 2px solid $border-color;
}

// Solid Tab Styles
.tab-solid{
	border: none;
	.nav-item{
		.nav-link{
			border: none;
			&.active{
				border: none;
				color: $white;
			}
		}
	}
}
.tab-content-solid{
	border: none;
	padding-top: 0.875rem;
	padding-left: 0;
	padding-right: 0;
}

@each $color, $value in $theme-colors {
  .tab-solid-#{$color} {
    @include tab-solid-variant($value);
  }
}

// Minimal Tab Styles
.tab-filled{
	@include border-radius(5px);
	.nav-tabs{
		border: none;
		.nav-item{
			.nav-link{
				border: none;
				&.active{}
			}
		}
	}
	.tab-content{
		@include border-radius(0px);
	}
}

@each $color, $value in $theme-colors {
  .tab-filled-#{$color} {
    @include tab-filled-variant($value);
  }
}

// Vertical Tab Styles
.vertical-tab{
	@include display-flex;
	.nav-tabs{
		@include flex-direction(column);
	}
	.tab-content{
		margin-bottom: 0px;
	}
}