/* Error */
.error-page {
	h1 {
		font-size: 9.375rem;
		line-height: 1;
		@media (max-width: 991px) {
			font-size: 8rem;
		}
	}

	h2 {
		font-size: 4.375rem;
		line-height: 1;
	}

	a,
	h1,
	h2,
	h3,
	h4,
	h5,
	p,
	span {
		@extend .text-white;
	}

	.error-page-divider {
		@media (min-width: 992px) {
			border-left: 3px solid rgba($white, .2);
		}
	}
}