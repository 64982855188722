/* Checkboxes and Radios */

.form-check,
.form-radio{
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  label{
    display: block;
    padding-left: 30px;
    font-size: .875rem;
    line-height: 1.42857143;
    input{
      position: absolute;
      margin-left: -20px;
      margin-top: 4px\9;
      top: 0;
      left: 0;
      margin-left: 0 !important;
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      margin-top: 0;
      &:checked{
        +.input-helper{
          &:before{
            background-color: $white;
          }
          &:after{
            color: theme-color(primary);
            opacity: 1;
            line-height: 1.5;
            filter: alpha(opacity=100);
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
          }
        }
      }
      &:disabled {
        + .input-helper {
          &:before {
            border-color: $border-color;
          }
        }
        &:checked {
          + .input-helper {
            &:before {
              background: color(gray-lightest);
            }
          }
        }
      }
    }
    .input-helper{
      &:before{
        position: absolute;
        content: "";
        top: 0;
        width: 19px;
        height: 19px;
        border-radius: 2px;
        left: 0;
        border: 2px solid color(gray);
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
      }
      &:after{
        line-height: 1.5;
        -webkit-transition: all;
        -o-transition: all;
        transition: all;
        transition-duration: 0s;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
        font-family: "Material Design Icons";
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        content: "\F12C";
        position: absolute;
        font-size: 0.875rem;
        left: 1px;
        top: -1px;
        color: them-color(white);
        font-weight: 700;
      }
    }
  }
  &.form-check-filled{
    .input-helper{
      &:before{
        background: theme-color(primary);
        border-color: theme-color(primary);
      }
    }
    input{
      &:checked{
        +.input-helper{
          &:before{
            background-color: theme-color(primary);
          }
          &:after{
            color: darken(theme-color(primary),20%);
          }
        }
      }
      &:disabled {
        + .input-helper {
          &:before {
            background: rgba(theme-color(primary),0.3);
            border-color: rgba(theme-color(primary),0.3);
          }
        }
        &:checked {
          + .input-helper {
            &:before {
              background: color(gray-lightest);
            }
            &:after{
              color: rgba(theme-color(primary),0.3);
            }
          }
        }
      }
    }
  }
}

.form-check{
  label{
    padding-left: 15px;
  }
}

.form-radio{
  label{
    padding-left: 35px;
    input{
      &:checked{
        +.input-helper{
          &:before{
            background-color: $white;
            border: 2px solid color(gray);
          }
        }
        &:disabled {
          +.input-helper{
            &:before{
              background-color: $white;
              border-color: $border-color;
            }
            &:after {
              background-color: color(gray-lightest);
            }
          }
        }
      }
    }
    .input-helper{
      &:before{
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
      &:after{
        content: "";
        width: 10px;
        height: 10px;
        background: theme-color(primary);
        border-radius: 50%;
        top: 6px;
        left: 6px;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
      }
    }
  }
  &.form-radio-filled{
    .input-helper{
      &:before{
        background: theme-color(primary);
        border:2px solid theme-color(primary);
      }
    }
    input{
      &:checked{
        +.input-helper{
          &:before{
            background-color: theme-color(primary);
            border:2px solid theme-color(primary);
          }
          &:after{
            background: darken(theme-color(primary),20%);
          }
        }
      }
      &:disabled {
        + .input-helper {
          &:before {
            background: rgba(theme-color(primary),0.3);
            border-color: rgba(theme-color(primary),0.3);
          }
        }
        &:checked {
          + .input-helper {
            &:before {
              background: color(gray-lightest);
              border:2px solid rgba(theme-color(primary),0.3);
            }
            &:after{
              background: rgba(theme-color(primary),0.3);
            }
          }
        }
      }
    }
  }
}
