#dashboard-radar-graph {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

#dashboard-chart-legend {
  ul {
    padding-left: 0;
    list-style: none;
    @include display-flex;

    li {
      @include display-flex;
      @include flex-direction(column);
      margin-right: 3.75rem;
      text-align: center;
      font-weight: bold;

      span {
        width: 20px;
        height: 20px;
        @include border-radius(100%);
        margin: 0 auto 13px;
        display: block;
      }
    }
  }
}

#dash-bar-chart {
  margin-top: 5rem;
}